<template>
  <div class="head">
    <div class="reserve">
      <img src="@/assets/change/newImg10.png" @click="clickHead">
    </div>

    <MobileReserveModels ref="MobileReserveModels" />
  </div>
</template>

<script>
import { reqGetLinks } from "@/api/news.js"
import { getLinksParams } from "@/utils/getDownloadLinks.js"
import device from '@/utils/GetDeviceInformation'
import MobileReserveModels from '@/components/MobileReserveModels'
export default {
  name: 'Head',
  components: { MobileReserveModels },
  data() {
    return {
      showGame: true,
      lostworld_ios: "",
      lostworld_android: "",
      lostworld_pc: ""
    }
  },
  created() {
    this.getDownloadLinks()
  },
  methods: {
    clickDownLoad(url, type) {
      const link = document.createElement('a');
      if (type === 'lostworld_android') {
        const ourkey = 'RILvS9HYMOsiFptQ75dgW38V6Bh2Jyw';
        const time = Date.parse(new Date()) / 1000;
        const uri = url.split("com")[1]; // 取com后面的所有值
        const key = this.$md5(`${uri}${ourkey}${time}`); // 转 md5 密钥计算参数

        link.href = url + `?key=${key}&time=${time}`; // 设置下载链接
      } else {
        link.href = url; // 设置下载链接
      }
      link.download = url.substring(url.lastIndexOf("/") + 1); // 设置下载文件的名称 取最后一个/后面的内容为下载名字
      // 将 <a> 标签添加到 DOM 中
      document.body.appendChild(link);
      // 触发点击事件
      link.click();
      // 删除添加的 <a> 标签
      document.body.removeChild(link);
    },
    clickHead() {
      if (device === 'ios') {
        this.clickDownLoad(this.lostworld_ios)
      } else {
        this.clickDownLoad(this.lostworld_android, 'lostworld_android')
      }
    },
    async getDownloadLinks() {
      const res = await reqGetLinks(getLinksParams)

      res.data.forEach(item => {
        this[item.keywords] = item.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1);
    /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1);
    /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}

.head {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  height: 59px;
  width: 100%;
  background: url('~@/assets/change/titleIcon.jpg') no-repeat center;
  background-size: 100%;
  display: flex;
  justify-content: flex-end;
  .reserve {
  color: #fff;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }
  img {
    width: 45%;
    // -webkit-animation-name: scaleDraw;
    // /*关键帧名称*/
    // -webkit-animation-timing-function: ease-in-out;
    // /*动画的速度曲线*/
    // -webkit-animation-iteration-count: infinite;
    // /*动画播放的次数*/
    // -webkit-animation-duration: 3s;
    // /*动画所花费的时间*/
  }
}
</style>
